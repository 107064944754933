/* eslint-disable class-methods-use-this */
import Service from '../Service';
import http from "@/http";

class LogsService extends Service {
    constructor() {
        super('/logs');
    }

    getResume(params) {
        return http.get(`${this.baseUrl}/resume`, { params });
    }

    getDailyLots(params) {
        return http.get(`${this.baseUrl}/lots`, { params });
    }

    getClientsWithErrors(params) {
        return http.get(`${this.baseUrl}/client-errors`, { params });
    }

    getErrors(params) {
        return http.get(`${this.baseUrl}/reference-errors`, { params });
    }

    getFieldsTable() {
        return [
            {
                key: 'reference',
                label: 'Referência',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'external_reference',
                label: 'Código no cliente',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'reason',
                label: 'Motivo',
                sortable: true,
            },
            {
                key: 'actions',
                label: 'Ações',
                tdClass: 'text-center',
                thClass: 'text-center',
            }
        ];
    }

    getFieldsTableResume() {
        return [
            {
                key: 'subsee_id',
                label: 'Código',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'client',
                label: 'Cliente',
                sortable: true,
            },
            {
                key: 'total_errors',
                label: 'Total de erros',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'actions',
                label: 'Ações',
                tdClass: 'text-center',
                thClass: 'text-center',
            }
        ];
    }

    getFieldsTableClientErrors() {
        return [
            {
                key: 'subsee_id',
                label: 'Código',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'client',
                label: 'Cliente',
                sortable: true,
            },
            {
                key: 'total_vintage',
                label: 'Total no vintage',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'total_portal',
                label: 'Total no portal',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'total_errors',
                label: 'Total de erros',
                sortable: true,
                tdClass: 'text-center',
                thClass: 'text-center',
            },
            {
                key: 'actions',
                label: 'Ações',
                tdClass: 'text-center',
                thClass: 'text-center',
            }
        ];
    }
}

const logsService = new LogsService();
export default logsService;
